import request from '@/utils/request_mall'
// 内容管理系统
// 上传oss
export function UpFlie(data) {
  return request({
    method: 'post',
    url: '/backend/v1/tools/upload_file_to_oos',
    data
  })
}
// 商品列表 
export function GetGoodslist(data) {
  return request({
    method: 'post',
    url: '/goods/goods/list',
    data
  })
}
// /backend/v1/channel/index

// /backend/v1/channel/delete
export function DeleteChannel(data) {
  return request({
    method: 'post',
    url: '/backend/v1/channel/delete',
    data
  })
}
// 获取频道树形 /backend/v1/channel/index
export function GetChannel(data) {
  return request({
    method: 'get',
    url: '/backend/v1/channel/index',
    data
  })
}
// 添加频道
export function AddChannel(data) {
  return request({
    method: 'post',
    url: '/backend/v1/channel/add',
    data
  })
}
// 编辑频道 
export function EditChannel(data) {
  return request({
    method: 'post',
    url: '/backend/v1/channel/edit',
    data
  })
}
// 获取频道的类型
export function GetChannelType(data) {
  return request({
    method: 'get',
    url: '/backend/v1/channel/channel_type_list',
    data
  })
}
// 拖拽排序频道
export function DragType(data) {
  return request({
    method: 'post',
    url: '/backend/v1/channel/drag_sort_channel',
    data
  })
}

// 图文列表
export function GetImgTxt(data) {
  return request({
    method: 'get',
    url: '/backend/v1/article/index',
    params: data
  })
}
// 添加图文 
export function AddImgTxt(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/add',
    data
  })
}
// 编辑图文
export function EditImgTxt(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/edit',
    data
  })
}
// 删除图文
export function DeleteImgTxt(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/delete',
    data
  })
}
// 移动图文
export function DragImgTxt(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/sort_article',
    data
  })
}
//图文详情  
export function DetailImgTxt(data) {
  return request({
    method: 'get',
    url: '/backend/v1/article/article_detail',
    params: data
  })
}
//上下架图文  
export function upImgTxt(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/shelve',
    data
  })
}
// 置顶图文
export function ToFirst(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/ontop_article',
    data
  })
}
// 上下移
export function ToTop(data) {
  return request({
    method: 'post',
    url: '/backend/v1/article/move_article',
    data
  })
}
 
