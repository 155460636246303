<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'添加':$route.query.type == 2?"查看":"编辑" }}</span>
    </div>
    <div class="content">
      <!-- 标题 -->
      <div class="titile pdl-60">
        <a-row class="flex mgt-2 mgb-20 titles">
          {{ form.title }}
        </a-row>
      </div>
      <!-- 富文本 -->
      <div
        class="htmlcontent"
        v-html="form.content"
      >
      </div>
      <div class="lines"></div>
      <div class="mgt-50 flex mgl-60">
        <span
          class="leftLable "
          :class="form.cover?'pdt-12':''"
        >封面</span>
        <img
          v-if="form.cover"
          class="rightLable"
          :src="form.cover"
          alt=""
        >
        <span
          v-else
          class="rightLable"
        >暂未上传图片</span>
      </div>
      <a-row class="flex mgt-50 mgl-60">
        <span class="leftLable">所属频道</span>
        <div class="rightLable">
          {{ form.channel_name||'--' }}
        </div>
      </a-row>
      <a-row class="flex mgt-50 mgl-60">
        <span class="leftLable">标签</span>
        <div class="rightLable">
          {{ form.tag_str ||'--' }}
        </div>
      </a-row>
    </div>
    <!-- 底部按鈕  -->
    <div class="last-btns-step2">
      <a-space :size="20">
        <a-button
          class="stepbackbtn bigheightbn wid-46"
          @click="watch()"
        >预览</a-button>
        <a-button
          class="stepbackbtn bigheightbn wid-46"
          @click="cancle()"
        >返回</a-button>
      </a-space>
    </div>
    <!-- 选择图片 -->
    <a-modal
      title="选择图片"
      :visible="imageObj.visible"
      @cancel="imageObj.visible=false;"
      @ok="addlinkokFn"
      :width="600"
      class="mrj_modal_normal"
    >
      <!-- 标题 传入 保存之后的id 还有type_id 4/8 -->
      <div
        class="flex"
        v-if="imageObj.list && imageObj.list.length"
      >
        <span
          class="broadImgSpan"
          v-for="(item,index) in imageObj.list"
          :key="index"
        >
          <img
            class="broadImg"
            :src="item"
            alt=""
            @click="query.cover=item;"
          >
          <span :class="query.cover==item?'disnone':'broad'">
            <a-icon type="check" />
          </span>
        </span>

      </div>
      <div v-else>暂未上传图片</div>
    </a-modal>
    <!-- 选择频道 -->
    <a-modal
      title="选择频道"
      :visible="openchannel.visible"
      @cancel=";openchannel.visible=false;"
      :width="600"
      @ok="publishFn"
      class="line-modal"
    >
      <div class="m-height400">
        <a-tree
          class="draggable-tree  pgl-24"
          show-icon
          :icon="customExpandIcon"
          :checkedKeys="channel_id"
          :tree-data="gData"
          :replace-fields="{children:'child', key:'id',  title: 'name'}"
        >
          <i
            slot="switcherIcon"
            class='meiye-icon meiye-jiantou meiye-jiantou_tree1'
            style="font-size: 12px;"
          ></i>
          <template
            slot="title"
            slot-scope="{ title }"
          >
            <span class="title1">
              <span>{{ title||'--' }}</span>
              <span> </span>
            </span>

          </template>
        </a-tree>
      </div>
    </a-modal>
    <!-- 预览 -->
    <a-modal
      title="预览"
      :visible="watchObj.visible"
      @cancel=";watchObj.visible=false;"
      :width="463"
      @ok="watchObj.visible=false;"
      class=""
      :footer="null"
    >
      <div class="imgbgcontent">
        <div class="centerBOX">
          <div class="imgbgTitle">
            {{ form.title }}
          </div>
          <div
            class="imgHtml"
            v-html="form.content"
          >
          </div>
        </div>

      </div>
    </a-modal>

  </div>
</template>
<script>
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import {
  GetChannel,
  GetImgTxt,
  DeleteImgTxt,
  AddImgTxt,
  DetailImgTxt
} from '@/api/content'
export default {
  inject: ['reload'],

  data() {
    return {
      imgflag: false,
      selectedKeys: [],
      html: '',
      baseURL: 'http://192.168.3.67:8081',
      isHot: [1],//置顶 热门 
      top: false,
      query: {
        title: undefined,
        cover: undefined,
        tag: [1, 2],
      },
      imageObj: {
        visible: false,
        list: [],
        image: '',
      },
      fileList: [],
      openchannel: {
        visible: false,
        selectedKeys: [],
      },
      gData: [],
      baseUrl: 'http://192.168.3.67:8081',
      channel_id: undefined,
      watchObj: {
        visible: false,
        html: '',
        title: '',
      },
      form: {},
    }
  },
  created() {
    this.getList()//DetailImgTxt
    this.getChannel()//
  },
  methods: {
    getList() {
      let data = {
        id: this.$route.query.id
      }
      DetailImgTxt(data).then(res => {
        if (res.error_code == 0) {
          this.form = res.data.data
        }
      })
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
      this.$forceUpdate();
    },
    getbrandsItem(i, v) {
      if (i == 'img') {
        this.selectedKeys = [v.text]
      }
    },
    // 拖拽上传
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 10
        if (isLt1M) {
          self.$message.error('上传文件大于10MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };

      let uploadApiUrl = this.baseUrl + '/api/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.query.cover = res.data.file_url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 素材内容中选取
    chooseFromEdit() {
      this.imageObj.visible = true
      console.log(this.$refs.edit.editor.children, '////this.$refs.edit.objHTML');
      let list = this.$refs.edit.editor.children
      this.imageObj.list = []
      this.getUrlList(list)
      console.log(this.imageObj.list);
      // 递归选出所有 type: "image"  src

    },
    getUrlList(data) {

      data.forEach(el => {
        if (el.type == 'image') {
          this.imageObj.list.push(el.src)
        }
        if (el.children && el.children.length) this.getUrlList(el.children);
      })
    },

    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        // headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    //一键置顶的效果
    handleScroll() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 200) {
        this.top = true;
      } else {
        this.top = false;
      }
    },
    toTop() {
      let Top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 设置计时器，实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = Top -= 50
        if (Top <= 10) {
          clearInterval(timeTop)
        }
      }, 20)
    },
    addlinkokFn() {
      this.query.cover = this.imageValue
    },
    // 发布1 草稿箱0
    publish(num) {
      this.openchannel.visible = true
    },
    publishFn() {
      let that = this
      let data = {
        title: that.form.title,
        cover: that.form.cover,
        tag: that.form.tag,
        product_image: that.form.product_image,
        link_url: that.form.link_url,
        desc: that.form.desc,
        appid_mini: that.form.appid_mini,
        content: that.form.content,
        type: that.form.type,
        leave_word: that.form.leave_word,
        channel_id: that.form.channel_id,
      }
      AddImgTxt(data).then(res => {
        if (res.error_code == 0) {
          that.$message.success('发布成功')
          that.$route.replace({
            path: '/content/sourcematerial'
          })
        } else {
          let self = that
          self.$confirm({
            icon: 'exclamation-circle',
            closable: true,
            title: '发布失败',
            content: (
              <div>
                失败原因
                <span class=' '>[{res.data.message}]</span>
              </div>
            ),
            okText: '去修改',
            closable: true,
            okType: 'primary',
            cancelText: '返回',
            onOk() {
              that.$route.replace({
                path: '/content/sourcematerial',
                // query: { type: 3, id}
              })
            },
            onCancel() {
            },
          });

        }

      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.child && props.child.length > 0) {
      } else {
        if (!props.id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    //递归 转化树形结构 
    treeRecursion(data) {
      data.forEach(el => {
        if (typeof (el.id) == 'number') {
          el.id = el.id.toString()
        }
        el['key'] = el.id
        el['title'] = el.name
        el['isEdit'] = false
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
    },
    getChannel() {
      GetChannel().then(res => {
        if (res.error_code == 0) {
          this.$message.success('加载成功')
          this.gData = res.data.list
          this.treeRecursion(this.gData)
        }
      }).finally(r => {
        // this.getList()
      })
    },
    watch() {
      this.watchObj.visible = true
      // this.watchObj.html = this.$refs.edit.html
      // this.watchObj.title = this.query.title

    },
    cancle() {
      this.$router.replace({
        path: '/content/imagetext'
      })
    },


  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  computed: {
  },

}
</script>
<style lang="less" scoped>
.addBox {
  // background: #fff;
  background-color: #f0f2f5;
  // padding: 0px 48px 0 0px;
  height: calc(100vh - 89px);
  display: flex;
  flex-direction: column;
}
.content {
  padding: 30px 48px 30px 0px;
  margin: 18px;
  margin-top: 20px;
  background: #fff;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 4px 4px 0 0;
  // padding-bottom: 120px;
  .chooses {
    display: inline-block;
    width: 228px;
    height: 36px;
    line-height: 36px;
    background: #f0f5f4;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(45, 56, 53, 0.8);
    text-align: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .imgdrop {
      width: 76px;
      text-align: center;
      height: 14px;
      line-height: 14px;
      display: inline-block;
      border-right: 1px solid #d0e3e1;
      .meiye-xiala {
        font-size: 12px;
        color: @fontColor1;
      }
    }
    .addvideo {
      width: 76px;
      height: 14px;
      height: 14px;
      line-height: 14px;
      display: inline-block;
      border-right: 1px solid #d0e3e1;
    }
    .addLink {
      width: 76px;
      display: inline-block;
    }
  }
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.last-btns-step2 {
  height: 68px;
  margin-left: 18px;
  width: calc(100vw - 217px);
  position: fixed;
  bottom: 18px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 70px;
}
.twoh2 {
  display: flex;
  margin-bottom: 10px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 2px;
    margin: 7px 8px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 25px;
  }
}
.noborder_input {
  border: none;
  box-shadow: none;
  background: #fff;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor1;
  &::placeholder {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: #a4b3b1;
  }
}
.upBox {
  width: 100%;
  text-align: center;
  .upcenter {
    height: 154px;
    width: auto;
    margin: 0 auto;
    img {
      width: auto;
      height: 154px;
    }
  }
  .upCom {
    display: inline-block;
    // width: 360px;
    width: 80%;
    height: 154px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.01);
    border-radius: 6px;
    border: 1px dashed #ccdada;
    &:hover {
      border: 1px dashed @primaryColor;
      background: rgba(11, 199, 185, 0.05);
    }
  }
  /deep/.ant-upload.ant-upload-drag {
    width: 100%;
    height: 154px;
    background: rgba(170, 170, 170, 0);
    border-radius: 6px;
    // border: 1px solid #ccdada;
    border: none;
  }
}
.leftLable {
  display: inline-block;
  width: 134px;
  margin-left: 10px;
}

/deep/.ant-checkbox-group {
  margin-top: 0px;
}
.fontC4 {
  color: #a4b3b1;
}
.wid-48 {
  width: 48px;
}
.wid-102 {
  width: 102px;
}
.meiye-leixing {
  color: rgb(158, 166, 164);
  font-size: 15px;
}
.pdl-60 {
  padding-left: 60px;
}
.mgt-2 {
  margin-top: 2px;
}
.mgb-20 {
  margin-bottom: 20px;
}
.mgt-52 {
  margin-top: 52px;
}
.color_y {
  color: red;
}
/deep/.ant-upload-list {
  display: none;
}
.meiye-shangchuan {
  font-size: 32px;
  color: @primaryColor;
}
.broadImgSpan {
  display: inline;
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 12px;
  .broadImg {
    width: 100%;
    height: 100%;
  }
  .broad {
    display: none;
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    i {
      color: #fff;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      z-index: 1000;
      font-weight: 600;
      font-size: 24px;
    }
  }
  &:hover {
    .broad {
      display: inline-block;
    }
  }
  .disnone {
    display: inline-block !important;
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    i {
      color: #fff;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 12px);
      z-index: 1000;
      font-weight: 600;
      font-size: 24px;
    }
  }
}
.mgt--12 {
  margin-top: -12px !important;
}
/deep/.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}
.imgbgcontent {
  width: 400px;
  height: 688px;
  background: url(../../../assets/images/phonebg.png) no-repeat 100% 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .centerBOX {
    width: 360px;
    margin: 27px 6px 16px 21px;
    overflow: auto;
  }
  .imgbgTitle {
    height: 60px;
    width: 330px;
    margin: 18px auto 12px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: @fontColor;
    overflow: hidden;
  }
  .imgHtml {
    flex: 1;
    width: 330px;
    // height: 545px;
    margin: 0px auto 34px;
    // overflow: hidden;
    // overflow: auto;
    // overflow: hidden;
    // overflow-y: auto;
  }
}
.titles {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor1;
}
.lines {
  margin-left: 56px;
  height: 1px;
  width: 100%;
  background: #d3dedc;
}
.htmlcontent {
  padding-left: 58px;
}
.mgt-50 {
  margin-top: 50px;
}
.mgl-60 {
  margin-left: 60px;
}
.pdt-12 {
  padding-top: 12px;
}
</style>