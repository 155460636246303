<template>
  <div class="pageBox">
    <div
      class="imagetext"
      v-if="pageStatus=='list'"
    >
      <div class="filter mgb-18">
        <a-input
          class="ant-input_1 wid-298 mgl-24 mgr-20"
          placeholder="输入标题、标签名称模糊查询"
          v-model="query.keyword"
          @pressEnter="getList"
        >
          <i
            class="meiye-icon meiye-sousuo1 font-14 font5"
            slot="prefix"
          ></i>
        </a-input>
        <a-range-picker
          class="data_picker mgr-16"
          v-model="dateRange"
          :disabledDate="disabledDate"
          @change="onChangeTime"
          format="YYYY-MM-DD"
        >
          <i
            slot="suffixIcon"
            class="meiye-icon meiye-rili-moren"
            style="font-size:12px;color: #CBD6DF;margin-top: -7px;"
          ></i>
        </a-range-picker>
        <a-button
          v-if="table.selectedRowKeys.length"
          class="ant-btn-grounding"
          @click="updown('')"
        >批量下架</a-button>
        <!-- <span class="datas  mgl-12">已选 <span class="baseColor11">{{ table.selectedRowKeys.length }}</span> 条数据</span> -->
      </div>
      <div class="content flex">
        <div class="treeData table_body">
          <a-tree
            class="draggable-tree mgt-18 pgl-24"
            show-icon
            :icon="customExpandIcon"
            :checkedKeys="selectedKeys"
            :tree-data="gData"
            @select="onSelect"
            :replace-fields="{children:'child', key:'id',  title: 'name'}"
          >
            <i
              slot="switcherIcon"
              class='meiye-icon meiye-jiantou meiye-jiantou_tree1'
              style="font-size: 12px;"
            ></i>
            <template
              slot="title"
              slot-scope="{ title }"
            >
              <span
                class="title1"
                :class="title.length >  5?'tools':''"
              >
                <a-tooltip>
                  <template slot="title">
                    {{title}}
                  </template>
                  <span class="tools">{{ title||'--' }}</span>
                </a-tooltip>

                <span> </span>
              </span>
            </template>
          </a-tree>
        </div>
        <div class="list">
          <!-- 列表 -->
          <a-table
            class="table-manage flex-1 pdt-18"
            @change="tableChanged"
            :pagination="table.pagination"
            :rowKey="record=>record.id"
            :loading="table.loading"
            :columns="config.imagetext.columns"
            :data-source="table.tableData"
            :scroll="{  y:getfullHeight(156 )}"
            :row-selection=" { selectedRowKeys: table.selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxPropsfn }"
            :customRow="customRow"
          >
            <template
              slot="_0"
              slot-scope="action, record"
            >
              <div class="pore">
                <span class="pore_txt">{{record.id}}</span>
                <span
                  class="pore_abs"
                  v-if="query.channel_id"
                >
                  <a-tooltip
                    placement="topLeft"
                    v-model="toolFlag"
                  >
                    <template slot="title">
                      拖拽列表进行排序
                    </template>
                    <i class="meiye-icon pdl-12 meiye-tuozhuaiyidong font5 font-14"></i>
                  </a-tooltip>
                </span>
              </div>
            </template>
            <template
              slot="_1"
              slot-scope="action, record"
            >
              <a-tooltip placement="topLeft">
                <template slot="title">
                  {{record.title}}
                </template>
                <span class="pointer">{{  record.title || '--'}}</span>
              </a-tooltip>
            </template>
            <template
              slot="_2"
              slot-scope="action, record"
            >
              <span> {{  record.tag_str || '--'}}</span>
            </template>
            <template
              slot="_4"
              slot-scope="action, record"
            >
              <span v-if="query.channel_id">
                <span v-if="!record.isShowFirst || !record.isShowup">
                  <span
                    class="totop"
                    @click="toTop(record,'toFirst')"
                    v-if="!record.isShowFirst"
                  ><i class="meiye-icon meiye-zhiding"></i>置顶</span>
                  <span
                    class="totop"
                    v-if="!record.isShowFirst"
                    @click="toTop(record,'toTop')"
                  ><i class="meiye-icon meiye-shangyi"></i> 上移</span>
                  <span
                    class="totop"
                    v-if="!record.isShowup"
                    @click="toTop(record,'toDown')"
                  ><i class="meiye-icon meiye-xiayi"></i> 下移</span>

                </span>
                <span
                  class="totop"
                  style="margin-left: -12px;"
                  v-else
                >--</span>
              </span>
              <span
                v-else
                class="mgl-8"
              >--</span>
            </template>
            <template
              slot="_7"
              slot-scope="action, record"
            >
              <a-button
                size="small"
                class="mgr-12 smallbtn"
                @click="openDetail(record)"
              >查看</a-button>
              <a-button
                size="small"
                class="mgr-12 smallbtn"
                @click="updown(record)"
              >下架</a-button>
            </template>
          </a-table>

        </div>
      </div>
    </div>
    <div
      class=" flex-1"
      v-if="pageStatus=='detail'"
    >
      <Detail></Detail>
    </div>
  </div>
</template>
<script>
import config from '../config';
import Detail from './detail'
import moment from 'moment';
import {
  GetChannel,
  GetImgTxt,
  upImgTxt,//下架
  DragImgTxt,//移动图文 
  ToTop,//上下移
  ToFirst,//置顶
} from '@/api/content'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
export default {
  components: { Detail },
  data() {
    return {
      config,
      toolFlag: false,
      pageStatus: 'list',//  detail
      gData: [
      ],
      flitList: [
        { key: '状态', value: [], paramas: 'goods_type' },
        { key: '发布/创建时间', value: [], paramas: 'goods_category' }
      ],
      table: {
        tableData: [
        ],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        }
      },
      isShowAll: true,
      dateRange: [],
      query: {
        start_time: undefined,
        end_time: undefined,
        keyword: undefined,
        channel_id: undefined,
        limit: 10,
        page: 1,
      },
      selectedKeys: [1],
      dragObj: {
        sort_old: undefined,
        sort_new: undefined,
        index1: undefined,
        index2: undefined,
        ids: [],
      },
      // startTime: '',
      // endTime: '',

    }
  },
  methods: {
    onDragEnter(info) {
      //拖拽排序调取接口   DragType
      console.log(info);
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    getInitializeDate() {
      let date = new Date()//获取新的时间
      //获取当前年份,并且转为字符串
      let year = date.getFullYear().toString()
      //获取当前月份，因为月份是要从0开始，此处要加1，
      //使用三元表达式，判断是否小于10，如果是的话，就在字符串前面拼接'0'
      let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
      //获取天，判断是否小于10，如果是的话，就在在字符串前面拼接'0'
      let day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
      //字符串拼接，将开始时间和结束时间进行拼接
      // let start = year + '-' + month + '-01'    //当月第一天
      let start = this.getBefore30()  // 
      let end = year + '-' + month + '-' + day  //当天
      this.dateRange = [start[0].BookedDate, end] //将值设置给组件DatePicker 绑定的数据

    },
    getBefore30() {
      var myDate = new Date();
      myDate.setDate(myDate.getDate() - 29);
      var dateTemp;
      var flag = 1;
      var day;
      var time = [];
      for (var i = 0; i < 30; i++) {
        if (myDate.getDate() < 10) {
          day = '0' + myDate.getDate().toString()
        } else {
          day = myDate.getDate().toString()
        }
        var month = myDate.getMonth() + 1;
        dateTemp = myDate.getFullYear() + "-" + (month < 10 ? '0' + month : '' + month) + "-" + day;
        time.push({
          BookedDate: dateTemp
        });
        myDate.setDate(myDate.getDate() + flag);
      }
      return time;
    },
    onDrop(info) {
      console.log(info);
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.child) {
            return loop(item.child, key, callback);
          }
        });
      };
      const data = [...this.gData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.push(dragObj);
        });
      } else if (
        (info.node.child || []).length > 0 && // Has child
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.gData = data;
    },
    //递归 转化树形结构 
    treeRecursion(data) {
      data.forEach(el => {
        if (typeof (el.id) == 'number') {
          el.id = el.id.toString()
        }
        el['key'] = el.id
        el['title'] = el.name
        el['isEdit'] = false
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.child && props.child.length > 0) {
      } else {
        if (!props.id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        // 分类筛选项
        if (e.key == "分类") {
          this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        }
        this.query.page = 1
        this.getList()
      } else {
        this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        // 全部清除
        this.clearfliterAll()
      }

    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList[1].value = []
      this.selectedKeys = []
      this.expandedKeys = []
      this.autoExpandParent = false
      this.backupsExpandedKeys = []
      this.permissionInfo.info.permission_id_list = []

      // // 清除状态
      // this.activeStatus_status = []
      // // 清除类别
      // this.activeStatus_catagory = []
      // // 清除品牌
      // this.activeStatus_brand = [] 
      this.flitList = [
        { key: '状态', value: [], paramas: 'goods_type' },
        { key: '发布/创建时间', value: [], paramas: 'goods_category' }
      ]
      this.isAllLength = ""
      this.query = {
        goods_type: undefined, //1客装 2院装 不传全部 
        goods_category: undefined, //分类id 不传为全部
        per_page: this.query.per_page,
        page: 1,
      },
        // todu 调取更新list的接口
        this.getList()
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        start_time: self.query.start_time,//商品名称
        end_time: self.query.end_time,
        keyword: self.query.keyword,
        channel_id: self.query.channel_id,
        page: self.query.page,
        limit: self.query.limit,
        is_publish: 1
      }
      GetImgTxt(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        // 第一个 
        if (self.query.page == 1 && self.table.tableData.length) {
          self.table.tableData[0]['isShowFirst'] = true
        }
        // 最后一位 
        if ((Math.floor((total / self.query.limit)) + 1) == self.query.page) {
          if (self.table.tableData.length) self.table.tableData[self.table.tableData.length - 1]['isShowup'] = true
        } else if ((total / self.query.limit) == 1) {
          if (self.table.tableData.length) self.table.tableData[self.table.tableData.length - 1]['isShowup'] = true
        }
        // isShowTools
        self.table.tableData.forEach((el, idx) => {
          self.table.tableData[idx]['isShowTools'] = true
        })
        console.log(self.table.tableData, "///self.table.tableData");
        self.onSelectChange([], [])
        self.table.loading = false
        self.$forceUpdate()
      }).finally(r => {
        self.table.loading = false
      })
    },
    getChannel() {
      GetChannel().then(res => {
        if (res.error_code == 0) {
          // this.$message.success(res.data.message)
          this.gData = res.data.list
          // this.query.channel_id = this.gData[0].id
          this.treeRecursion(this.gData)
        }
      }).finally(r => {
        this.getList()
      })
    },

    openDetail(record) {
      this.$router.push({
        path: '/content/imagetext', query: {
          type: 2,
          id: record.id
        }
      })
    },
    // table 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.table.selectedRowKeys = selectedRowKeys
      this.table.selectedRows = selectedRows
    },
    setMonth(date, dateString) {
      this.visibleTime = false;
      this.query.start_data = dateString[0]
      this.query.end_data = dateString[1]
      this.flitList[1].value = [this.query.start_data + ' ~ ' + this.query.end_data]
      // this.query.dividend_month = dateString;
      this.getList();
    },
    getCheckboxPropsfn(record) {
      return {
        props: {
          disabled: record.status_text == "已结束" // 禁选项
        }
      };
    },
    onChangeTime(rec) {
      this.visibleTime = false;
      console.log('rec :>> ', rec);
      this.query.start_time = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.query.end_time = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      this.getList()
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      // self.query.page = 1
      this.getList()
    },
    //置顶toFirst 上移 toTop  下移 toDown
    toTop(record, key) {
      let data = {}
      if (key == 'toFirst') {//置顶
        data = {
          id: record.id
        }
        ToFirst(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.getList()
          }
        })
        return false
      }
      if (key == 'toTop') {
        data = {
          id: record.id,
          type: 1
        }
      }
      if (key == 'toDown') {
        data = {
          id: record.id,
          type: 2
        }
      }
      ToTop(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.getList()
        }
      })
    },
    onSelect(selectedKeys, info) {
      console.log('selected', selectedKeys, info);
      this.selectedKeys = selectedKeys
      this.query.channel_id = selectedKeys[0]
      this.getList()
    },
    // 下架
    updown(record) {
      let data = {}
      if (record) {//单个下架
        data = {
          id: [record.id],
          shelve: 0,
          channel_id: this.query.channel_id ? this.query.channel_id : 0
        }
      } else {
        data = {
          id: this.table.selectedRowKeys,
          shelve: 0,
          channel_id: this.query.channel_id ? this.query.channel_id : 0
        }
      }
      let self = this
      self.$confirm({
        title: '提示',
        icon: 'exclamation-circle',
        closable: true,
        content: (
          <div>  下架之后小程序上将不展示此内容，是否确认下架？ </div>
        ),
        okText: '确认',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          upImgTxt(data).then(res => {
            if (res.error_code == 0) {
              self.$message.success('下架成功！可前往草稿箱中查看')
              self.query.page = 1
              self.query.limit = 10
              self.query.start_time = undefined
              self.query.end_time = undefined
              self.dateRange = []
              self.query.keyword = undefined
              // self.query.channel_id = self.gData[0].id
              self.getList()
            }
          })

        },
        onCancel() {
        },
      });


    },
    // 列表 行事件
    customRow(record, index) {
      let that = this
      return {
        style: {
          cursor: that.query.channel_id ? 'move' : undefined
        },
        on: {
          mouseenter: event => {
            var ev = event || window.event
            if (that.query.channel_id) {
              ev.target.draggable = true
            }
            // let a = ev.target.parentNode.parentNode.parentNode
            // console.log(ev.target.parentNode.parentNode.parentNode.parentNode);
            // debugger
            // a.style.borderBottom = '1px solid red'
          },
          mouseleave: event => {
            var ev = event || window.event
            if (that.query.channel_id) {
              ev.target.draggable = true
            }
          },
          mousedown: event => {
            var ev = event || window.event
            that.toolFlag = false
            that.table.tableData.forEach((el, idx) => {
              if (el.id == record.id) {
                that.table.tableData[idx]['isShowTools'] = false
              }
            })
          },
          dragstart: event => {
            var ev = event || window.event
            ev.stopPropagation()
            console.log(record, 'record111');
            that.dragObj.index1 = index
            that.dragObj.sort_old = record.index
          },
          drag: event => {
            var ev = event || window.event
            if (that.query.channel_id) {
              ev.target.draggable = true
            }
            // let a = ev.target.parentNode.parentNode
            // console.log(ev.target.parentNode.parentNode.parentNode.parentNode);
            // debugger
            // a.style.borderBottom = '1px solid red'
          },
          dragenter: event => {
            var ev = event || window.event
            // let a = ev.target.parentNode.parentNode
            // // console.log(ev.target.parentNode.parentNode.parentNode.parentNode);
            // // debugger
            // a.style.borderBottom = '1px solid red'
          },
          dragover: event => {
            var ev = event || window.event
            ev.preventDefault()
            // let a = ev.target.parentNode.parentNode
            // // console.log(ev.target.parentNode.parentNode.parentNode.parentNode);
            // // debugger
            // a.style.borderBottom = 'none'
            // debugger
          },
          // 鼠标松开
          drop: event => {
            var ev = event || window.event
            ev.stopPropagation()
            that.dragObj.index1 = index
            that.dragObj.sort_new = record.index
            // 搞事情 列表替换顺序 
            if (that.query.channel_id) {
              that.pushIndex()
            }
            that.table.tableData.forEach((el, idx) => {
              if (el.id == record.id) {
                that.table.tableData[idx]['isShowTools'] = true
              }
            })
            // let a = ev.target.parentNode.parentNode.parentNode
            // console.log(ev.target.parentNode.parentNode.parentNode.parentNode);
            // debugger
            // a.style.borderBottom = 'none'
          },
        },
      }

    },
    pushIndex() {  // 发送拖拽请求
      let ids = []
      this.table.tableData.forEach(el => {
        ids.push(el.id)
      })
      let data = {
        ids: ids,
        sort_old: this.dragObj.sort_old,
        sort_new: this.dragObj.sort_new,
      }
      DragImgTxt(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.getList()
        }
      })

    },
    // 限制不能选择今日以后的时间
    disabledDate(current) {
      return current && current > moment().endOf('day')
    }

  },
  created() {
    if (this.$route.query.type && this.$route.query.type == 1) {// 添加
      this.pageStatus = 'create'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)

    } else if (this.$route.query.type && this.$route.query.type == 3) { //编辑
      this.pageStatus = 'create'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 2) { //查看
      this.pageStatus = 'detail'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else { //列表
      this.pageStatus = 'list'
      this.getChannel()
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', true)
        clearTimeout(timers)
        timers = ''
      },)
    }
    this.getInitializeDate();
    // this.startTime = moment(Date()).subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss')
    // this.endTime = moment(Date()).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
    // this.startTime = this.momentTime(this.startTime, 'YYYY-MM-DD HH:mm:ss')
    // this.endTime = this.momentTime(this.endTime, 'YYYY-MM-DD HH:mm:ss')
  },
  mounted() {
    this.dateRange = []
  },

}
</script>
<style lang="less" scoped>
.imagetext {
  margin: 18px 12px 18px 18px;
  background: #f0f5f4;
  height: calc(100vh - 90px);
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  .filter {
    width: 100%;
    height: 71px;
    line-height: 71px;
    background: #ffffff;
    border-radius: 4px;
  }
  .content {
    // background: #fff;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .treeData {
      width: 310px;
      margin-right: 8px;
      background: #fff;
      border-radius: 4px;
      padding-right: 20px;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
    }
    .list {
      flex: 1;
      background: #fff;
      border-radius: 4px;
    }
  }
}
.wid-298 {
  width: 298px;
}
.font-14 {
  font-size: 14px;
}
/deep/.ant-input_1 .ant-input {
  padding-left: 32px;
}
/deep/.ant-input_1 .ant-input-prefix {
  left: 11px;
}
.pointer_tree {
  display: block;
  margin-top: 9px;
  width: 6px;
  height: 6px;
  background: @fontColor5;
  border-radius: 50%;
  margin-left: 3px;
}
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
.table_body {
  padding-left: 20px;
}
/deep/.ant-tree {
  padding-right: 24px;
  overflow: hidden;
  // padding-bottom: 100px;
  .title1 {
    display: inline-block;
    min-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: #e4f7f6;
  border-radius: 4px;
  color: @baseColor11;
}
.totop {
  display: inline-block;
  width: 52px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  background: #fcffff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  margin-right: 12px;
  cursor: pointer;
  background: transparent;
  &:hover {
    background: #fcffff;
  }
  .meiye-icon {
    font-size: 14px;
  }
  &:hover {
    color: @baseColor11;
    .meiye-icon {
      color: @baseColor11;
    }
  }
}
//
/deep/ .ant-table-row {
  position: relative;
  &:hover {
    .pore_abs {
      display: inline-block;
    }
  }
}
.pore {
  .pore_abs {
    position: absolute;
    // display: inline-block;
    display: none;
    // left: 8px;
    left: -1px;
    .meiye-routeicon-drag {
      padding-left: 13px;
    }
  }
}
/deep/.table-manage .ant-table-row > td:nth-child(1),
/deep/.table-manage .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 32px !important;
}
@media screen and (max-height: 816px) {
  /deep/ .table-manage .ant-table-header {
    margin-bottom: -8px !important;
  }
}
@media screen and (max-height: 816px) {
  /deep/ .table-manage .ant-table-header {
    margin-bottom: -6px !important;
  }
}
/deep/.table-manage .ant-table-placeholder {
  margin-top: 0px;
  border-top: 0px solid #e8e8e8;
}
/deep/.ant-calendar-picker-input {
  border: 1px solid #bacbc7 !important;
}
.mgt-18 {
  margin-top: 18px;
}
.pdt-18 {
  padding-top: 18px;
}
.table-manage {
  height: calc(100% - 90px);
}
.tools {
  display: inline-block;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdl-12 {
  padding-left: 12px;
}
/deep/.ant-calendar-range-picker-input::placeholder {
  color: #a4b3b1;
}
</style>