// 数据colom
export default {
  imagetext: {
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: '_0',
        // ellipsis: true,
        width: 80,
        scopedSlots: { customRender: '_0' },
      },
      {
        title: '标题',
        dataIndex: 'title',
        key: '_1',
        ellipsis: true,
        scopedSlots: { customRender: '_1' },
      },
      {
        title: '标签',
        dataIndex: 'tag_str',
        key: '_2',
        ellipsis: true,
        scopedSlots: { customRender: '_2' },
      },
      {//todo
        // slots: { title: 'publish_time' },
        title: '发布时间',
        dataIndex: 'publish_at',
        key: '_3',
        ellipsis: true,
        scopedSlots: { customRender: '_3' },
      },
      {
        title: '排序',
        dataIndex: 'permission_route',
        key: '_4',
        width:280,
        ellipsis: true,
        scopedSlots: { customRender: '_4' },
      },
      {
        title: '操作',
        // slots: { title: 'action' },
        dataIndex: 'action',
        key: '_7',
        width: 165,
        scopedSlots: { customRender: '_7' },
      },
    ]
  }
}